import { createContext, useContext } from 'react';
import { ConfigurationKey } from '../constants/marketConfigs';

export type MarketConfigurationsObject = { [key in ConfigurationKey]: boolean };

export const initialMarketConfig: MarketConfigurationsObject = {
  mapReboundMaxRadius1000km: false,
  useAorRetailersAndZipCodeSearch: false,
  coordinatesMapLink: false,
};

export const MarketConfigContext =
  createContext<MarketConfigurationsObject>(initialMarketConfig);

export const useMarketConfig = () => useContext(MarketConfigContext);
