// @ts-check

/**
 * @param {string} path
 * @returns {string}
 */
function addLeadingSlash(path) {
  if (path[0] !== '/') return `/${path}`;
  return path;
}

/**
 * @param {string} path
 * @returns {string}
 */
function removeLeadingSlash(path) {
  if (path[0] === '/') return path.replace(/^\/+/, '');
  return path;
}

/**
 * @param {string} path
 * @returns {string}
 */
export function addTrailingSlash(path) {
  if (path[path.length - 1] !== '/') return `${path}/`;
  return path;
}

/**
 * @param {string} path
 * @returns {string}
 */
function removeTrailingSlash(path) {
  if (path[path.length - 1] === '/') return path.replace(/\/+$/, '');
  return path;
}

/**
 * @overload
 * @param {string} path
 * @param {Object} [options]
 * @param {boolean} [options.start]
 * @param {boolean} [options.end]
 * @returns {string}
 */
/**
 * @overload
 * @param {string} [path]
 * @param {Object} [options]
 * @param {boolean} [options.start]
 * @param {boolean} [options.end]
 * @returns {string | undefined}
 */
/**
 * @param {string} [path] - string to toggle slashes on
 * @param {Object} [options] - options to choose what to toggle
 * @param {boolean} [options.start=false] - toggle leading slash
 * @param {boolean} [options.end=false] - toggle trailing slash
 * @returns {string | undefined}
 */
export function toggleSlashes(path, { start = false, end = false } = {}) {
  if (!path) return path;

  let newPath = path;

  if (start) {
    newPath = addLeadingSlash(newPath);
  } else {
    newPath = removeLeadingSlash(newPath);
  }

  if (end) {
    newPath = addTrailingSlash(newPath);
  } else {
    newPath = removeTrailingSlash(newPath);
  }

  return newPath;
}
