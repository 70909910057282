import Cookies from 'js-cookie';
import throttle from 'lodash/throttle';
import { hasTrackingConsent } from './hasTrackingConsent';

export interface TrackingSessionConfig {
  /**
   * Session ID as a Unix Timestamp. Active for 30 minutes when no events are sent.
   *
   * A new ID is generated when no events are sent for 30 minutes.
   */
  sessionId: number;
  /**
   * Device ID as a UUID. Generated once per device.
   */
  deviceId: string;
  /**
   * Last event time as a Unix Timestamp. Used to calculate session ID lifetime.
   */
  lastEventTime: number;
}

export class TrackingSession {
  private readonly cookieName: string = 'wp-tracking';
  private readonly localStorageKey: string = 'wp-tracking-data';
  private readonly sessionTimeout: number = 30 * 60 * 1000; // 30 minutes
  private config: TrackingSessionConfig | undefined;
  private queueTimer: NodeJS.Timeout | undefined;

  constructor() {
    // There is no need to keep generating the config on every event.
    this.generateConfig = throttle(
      this.generateConfig.bind(this),
      2000,
    ) as typeof this.generateConfig;
  }

  // async to future proof for when https://developer.mozilla.org/en-US/docs/Web/API/Cookie_Store_API is used
  async generateConfig(): Promise<TrackingSessionConfig> {
    const configValue = this.getConfigFromCookie() || this.config;

    const config: TrackingSessionConfig = {
      sessionId: this.getSessionId(
        configValue?.sessionId,
        configValue?.lastEventTime,
      ),
      deviceId: configValue?.deviceId || this.generateDeviceId(),
      lastEventTime: Date.now(),
    };

    // Save config to instance in case it's not saved to cookie when consent is not given
    // So that when it's given later, we can save it then
    this.config = config;

    this.saveCookieValue(config);

    return config;
  }

  private getSessionId(
    currentSessionId?: number,
    lastEventTime?: number,
  ): number {
    const now = Date.now();

    if (!currentSessionId || !lastEventTime) {
      return now;
    }

    return now - lastEventTime > this.sessionTimeout ? now : currentSessionId;
  }

  private generateDeviceId(): string {
    return crypto.randomUUID();
  }

  private saveCookieValue(value: TrackingSessionConfig): void {
    try {
      if (!hasTrackingConsent()) {
        this.queueSaveCookieValue(value);
        return;
      }

      // Clear any queued save
      if (this.queueTimer) {
        clearInterval(this.queueTimer);
        this.queueTimer = undefined;
      }

      const encodedValue = btoa(encodeURIComponent(JSON.stringify(value)));

      Cookies.set(this.cookieName, encodedValue, {
        expires: 365,
        sameSite: 'Lax',
      });
    } catch (error) {
      console.error('Error setting cookie:', error);
    }
  }

  private queueSaveCookieValue(value: TrackingSessionConfig): void {
    if (this.queueTimer) {
      clearInterval(this.queueTimer);
    }

    this.queueTimer = setInterval(() => {
      clearInterval(this.queueTimer);
      this.queueTimer = undefined;

      this.saveCookieValue(value);
    }, 2000);
  }

  private getConfigFromCookie(): Partial<TrackingSessionConfig> | undefined {
    try {
      const cookieValue = Cookies.get(this.cookieName);

      if (!cookieValue) {
        return;
      }

      return JSON.parse(decodeURIComponent(atob(cookieValue)));
    } catch (error) {
      console.error('Error getting cookie:', error);
    }
  }

  private getSessionStorageData(): SessionLocalStorageData | undefined {
    try {
      const storageString = localStorage.getItem(this.localStorageKey);

      if (!storageString) {
        return;
      }

      const storageData = JSON.parse(storageString) as SessionLocalStorageData;

      if (
        !storageData.sessionId ||
        !this.config?.sessionId ||
        storageData.sessionId !== this.config.sessionId
      ) {
        return;
      }

      return storageData;
    } catch {
      // empty
    }
  }

  /**
   * Sets an item in the session storage if tracking consent is given.
   *
   * If the configuration is not available, it generates the configuration.
   *
   * Updates the local storage with the new item.
   *
   * Uses local storage instead of session storage to store the data so that
   * it's available across tabs.
   */
  async setItem<T extends StorageItemKey>(key: T, value: StorageItemValue<T>) {
    if (!hasTrackingConsent()) {
      return;
    }

    if (!this.config) {
      await this.generateConfig();
    }

    const storageData = this.getSessionStorageData();

    if (!storageData) {
      localStorage.setItem(
        this.localStorageKey,
        JSON.stringify({
          sessionId: this.config?.sessionId,
          data: { [key]: value },
        }),
      );
      return;
    }

    localStorage.setItem(
      this.localStorageKey,
      JSON.stringify({
        ...storageData,
        data: { ...storageData.data, [key]: value },
      }),
    );
  }

  /**
   * Retrieves an item from the session storage data using the specified key.
   *
   * If the configuration is not yet generated, it will generate the
   * configuration first.
   */
  async getItem(key: StorageItemKey) {
    if (!this.config) {
      await this.generateConfig();
    }

    const storageData = this.getSessionStorageData();

    return storageData?.data?.[key];
  }
}

type StorageItemKey = keyof NonNullable<SessionLocalStorageData['data']>;
type StorageItemValue<T extends StorageItemKey> = NonNullable<
  SessionLocalStorageData['data']
>[T];

interface SessionLocalStorageData {
  sessionId?: number;
  data?: {
    previousPage: {
      name?: string | undefined;
      url?: string | undefined;
    };
  };
}
declare global {
  interface Window {
    volvoCarsTrackingSession?: TrackingSession;
  }
}

/**
 * Returns currently active tracking session instance if available. Otherwise, creates a
 * new instance and attaches it to the window object.
 */
export function getTrackingSession() {
  if (typeof window === 'undefined') {
    return;
  }

  if (!window.volvoCarsTrackingSession) {
    const trackingSession = new TrackingSession();
    attachTrackingSession(trackingSession);

    return trackingSession;
  }

  return window.volvoCarsTrackingSession;
}

// Best to initialize it very early on so it's available
// on window as soon as possible
getTrackingSession();

function attachTrackingSession(trackingSession: TrackingSession) {
  Object.defineProperty(window, 'volvoCarsTrackingSession', {
    value: trackingSession,
    enumerable: true,
    writable: false,
    configurable: true,
  });

  // Dispatch an event to notify other listeners that the tracking session is
  // ready to be used
  dispatchEvent(
    new CustomEvent(`init-wp-tracking`, { detail: trackingSession }),
  );
}
