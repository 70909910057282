const params = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
] as const;

type Params = Partial<Record<(typeof params)[number], string>>;

export function extractCampaignParams() {
  if (typeof window === 'undefined') {
    return {};
  }

  if (!('location' in window && window.location.href)) {
    return {};
  }

  const url = new URL(window.location.href);

  return params.reduce<Params>((acc, param) => {
    if (url.searchParams.has(param)) {
      acc[param] = url.searchParams.get(param) ?? '';
    }
    return acc;
  }, {});
}
