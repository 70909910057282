const flagGroups: FlagGroup[] = [
  'setting',
  'feature',
  'operations',
  'experiment',
];
type FlagGroup = keyof Flags;

export type RawFeatureFlags = object;

export type Flags = {
  setting?: object;
  feature?: object;
  operations?: object;
  experiment?: object;
};

export const parseFeatureFlags = (flags: RawFeatureFlags) => {
  const parsedFlags = Object.entries(flags).reduce((acc, [key, value]) => {
    const [group, flagName] = key?.split('.') as [keyof Flags, string];
    if (flagGroups.includes(group)) {
      if (acc[group]) {
        acc[group] = { ...acc[group], [flagName]: value || false };
      } else {
        acc[group] = { [flagName]: value || false };
      }
    }
    return acc;
  }, {} as Flags);
  return parsedFlags;
};
